import { render, staticRenderFns } from "./Imagee.vue?vue&type=template&id=988dad5a&scoped=true"
import script from "./Imagee.vue?vue&type=script&lang=js"
export * from "./Imagee.vue?vue&type=script&lang=js"
import style0 from "./Imagee.vue?vue&type=style&index=0&id=988dad5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "988dad5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/opt/build/repo/components/Video.vue').default})
