
export default {
  name: 'Imagee',
  props: ['src', 'alt', 'backdrop', 'video', 'bg', 'invisible', 'radius', 'sizes', 'width', 'height'],
  computed: {
    provider(){
      return this.src.includes('images.prismic.io') ? 'prismic' : 'static'
    },
    backgroundImageUrl() {
      return this.$img(this.src, { provider: this.provider })
    }
  }
}
